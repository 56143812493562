<template>
  <div id="app">
    <router-view name="header" />
    <div>
      <router-view :key="$route.path" />
    </div>
    <router-view name="footer" />
    <portal-target name="modals" />
  </div>
</template>
<script>
  export default {}
</script>

