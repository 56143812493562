<template>
    <footer
        class="footer"
        :class="{ [`footer-${type}`]: type }"
        :data-background-color="backgroundColor"
    >
        <div class="container">
            <div class="text-center">
                @m
            </div>
            <nav v-if="false">
                <ul>
                    <li>
                        <a href="https://www.creative-tim.com">
                            Creative Tim
                        </a>
                    </li>
                    <li>
                        <a href="https:///presentation.creative-tim.com">
                            About Us
                        </a>
                    </li>
                    <li>
                        <a href="https:///blog.creative-tim.com"> Blog </a>
                    </li>
                    <li>
                        <a href="https://www.creative-tim.com/license">
                            License
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </footer>
</template>
<script>
export default {
    props: {
        backgroundColor: String,
        type: String,
    },
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
};
</script>
<style>
</style>
