
<script>
import { Card, Button, FormGroupInput } from "@/components";
import MainFooter from "@/layout/MainFooter";
import Login from "@/services/Login";
import VLoader from "@/components/VLoader";

export default {
    name: "LoginPage",
    bodyClass: "login-page",
    components: {
        Card,
        MainFooter,
        VLoader,
        [Button.name]: Button,
        [FormGroupInput.name]: FormGroupInput,
    },
    data() {
        return {
            loading: false,
            username: "",
            password: "",
            hasError: false,
        };
    },
    methods: {
        async doLogin() {
            try {
                this.loading = true;
                const r = await Login.login(this.username, this.password);
                this.$router.push({name: 'doc', params: {lang: 'toc', doc: this.$i18n.locale}});
                this.hasError = false;
            } catch (e) {
                console.log("Login e", e);
                this.hasError = true;
            }

            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="page-header header-filter" filter-color="black">
        <div
            class="page-header-image"
            style="background-image: url('img/bgs/marina.jpg')"
        />
        <div class="content">
            <div class="container">
                <div class="col-md-5 ml-auto mr-auto">
                    <card type="login" plain>
                        <div slot="header" class="logo-container">
                            <i class="fas fa-sign-in-alt fa-3x"></i>
                        </div>

                        <div class="alert alert-danger alert-outline" v-if="hasError">
                            {{$t('login.wrong_username_or_password')}}
                        </div>

                        <fg-input
                            v-model="username"
                            class="no-border input-lg"
                            addon-left-icon="now-ui-icons users_circle-08"
                            :placeholder="$t('login.login_placeholder')"
                        />

                        <fg-input
                            v-model="password"
                            type="password"
                            class="no-border input-lg"
                            addon-left-icon="now-ui-icons text_caps-small"
                            :placeholder="$t('login.password_placeholder')"
                        />

                        <template slot="raw-content">
                            <div class="card-footer text-center">
                                <button
                                    type="button"
                                    @click="doLogin"
                                    :disabled="loading"
                                    class="btn btn-primary btn-round btn-lg btn-block"
                                >
                                    <i
                                        v-if="loading"
                                        class="fas fa-spinner fa-pulse mr-1"
                                    ></i>
                                    {{ $t("login.btn_login") }}
                                </button>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<style>
</style>
