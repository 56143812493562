import {Parallax} from '../components';
import PortalVue from 'portal-vue'
import VueImg from 'v-img';




/**
 * You can register global components here
 */

const globalComponents = {
  install (Vue) {
    Vue.component(Parallax.name, Parallax)
    Vue.use(PortalVue)
    Vue.use(VueImg);
  }
}

export default globalComponents
