
<script>
export default {};
</script>

<template>
    <div class="fa-5x text-center">
        <i class="fas fa-spinner fa-pulse"></i>
    </div>
</template>

<style>
</style>
