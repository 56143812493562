<script>
import { DropDown, Navbar, NavLink } from "@/components";

export default {
    name: "MainNavbar",
    components: {
        DropDown,
        Navbar,
        NavLink,
    },
    props: {
        transparent: Boolean,
        colorOnScroll: Number,
    },
    methods: {
        setLocale(locale) {
            this.$i18n.locale = locale;
        }
    }
};
</script>

<template>
    <navbar
        position="fixed"
        :transparent="transparent"
        :color-on-scroll="colorOnScroll"
        menu-classes="ml-auto"
    >
        <template v-if="false">
            <router-link class="navbar-brand" to="/presentation">
                Now Ui Kit Pro
            </router-link>
        </template>
        <template slot="navbar-menu">
            <li class="nav-item">
                <span class="nav-link" @click="setLocale('en')"><i class="far fa-flag mr-1"></i> English</span>
            </li>
            <li class="nav-item">
                <span class="nav-link" @click="setLocale('pl')"><i class="far fa-flag mr-1"></i> Polski</span>
            </li>
            <li class="nav-item">
                <router-link to="/albums" class="nav-link"><i class="far fa-images mr-1"></i> {{$t('frontpage.albums')}}</router-link>
            </li>
            <li class="nav-item">
                <router-link to="/login" class="nav-link"><i class="fas fa-sign-in-alt mr-1"></i> {{$t('frontpage.login')}}</router-link>
            </li>
            <li class="nav-item">
                <router-link to="/" class="nav-link"><i class="fas fa-home mr-1"></i> {{$t('frontpage.home')}}</router-link>
            </li>
            <drop-down
                v-if="false"
                tag="li"
                title="Components"
                icon="now-ui-icons design_app"
                class="nav-item"
            >
                <nav-link to="/components">
                    <i class="now-ui-icons business_chart-pie-36" /> Components
                </nav-link>
                <a
                    href="https://demos.creative-tim.com/vue-now-ui-kit-pro/documentation"
                    target="_blank"
                    class="dropdown-item"
                >
                    <i class="now-ui-icons design_bullet-list-67" />
                    Documentation
                </a>
            </drop-down>

            <li class="nav-item" v-if="false">
                <router-link
                    class="nav-link btn btn-primary"
                    to="/docs/test"
                    target="_blank"
                >
                    <p>{{$t('frontpage.login')}}</p>
                </router-link>
            </li>
        </template>
    </navbar>
</template>



<style scoped>
</style>
